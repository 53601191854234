<template>
  <div class="order-upload">
    <a-table
      v-if="order !== null"
      :columns="columns"
      :data-source="orderStatuses"
      :rowKey="row => row.id"
    >
      <span slot="files" slot-scope="item">
        <template v-for="file in item.files">
          <a :key="file.id" :href="file.original_url" target="_blank">
            открыть [{{ file.mime_type }}]
          </a>
        </template>
      </span>

      <span slot="updated_at" slot-scope="item">
        {{ item.updated_at | date }}
      </span>
    </a-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"

export default {
  name: "OrderStatusHistory",
  data: () => ({
    columns: [
      {
        title: "Статус",
        dataIndex: "status_history_name",
        key: "status_history_name",
      },
      {
        title: "Комментарий",
        dataIndex: "comment",
        key: "comment",
      },
      {
        title: "Файлы",
        key: "files",
        scopedSlots: { customRender: "files" },
      },
      {
        title: "Обновлен",
        key: "updated_at",
        scopedSlots: { customRender: "updated_at" },
      },
    ],
  }),
  computed: {
    ...mapGetters({
      currentOrderStatuses: "orders/currentOrderStatuses",
      order: "orders/currentOrder",
    }),
    orderStatuses() {
      return [...this.order.statuses]
    },
  },
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY HH:mm")
    },
  },
}
</script>

<style lang="scss">
.order-correction-history {
  width: 100%;
}
</style>

<template>
  <div>
    <a-modal
      :visible="correctionModalVisible"
      title="Коррекция"
      centered
      :maskClosable="false"
      cancel-text="Отмена"
      ok-text="Сохранить"
      @cancel="setCorrectionModalVisible(false)"
      @ok="saveCorrection(order.uuid, correctedProducts)"
    >
      <a-table
        v-if="order"
        :columns="columns"
        :data-source="order.basket.products"
        :rowKey="row => row.id"
      >
        <span slot="count" slot-scope="item">
          <a-input
            ref="productCount"
            :max="item.count"
            @change="
              onCorrectedProductChange(
                item.number,
                $event.target.value,
                item.count
              )
            "
            :value="item.count"
            placeholder=""
          />
        </span>
      </a-table>

      <a-textarea
        required
        @change="comment = $event.target.value"
        placeholder="Введите комментарий"
      ></a-textarea>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import { message } from "ant-design-vue"

export default {
  name: "OrderDetailsCorrection",
  props: {
    order: null,
  },
  data: () => ({
    columns: [
      {
        title: "Наименование",
        dataIndex: "catalog_product.name",
        key: "catalog_product.name",
      },
      {
        title: "Цена",
        dataIndex: "calculate_price",
        key: "price",
      },
      {
        title: "Кол-во",
        key: "count",
        scopedSlots: { customRender: "count" },
      },
    ],
    comment: "",
    correctedProducts: [],
  }),
  computed: {
    ...mapGetters({
      correctionModalVisible: "orders/correctionModalVisible",
    }),
  },
  methods: {
    ...mapMutations({
      setCorrectionModalVisible: "orders/SET_CORRECTION_MODAL_VISIBLE",
      setCorrectionId: "orders/SET_CORRECTION_ID",
    }),
    ...mapActions({
      setStatusCorrection: "orders/setStatusCorrection",
      storeCorrection: "orders/storeCorrection",
    }),
    onCorrectedProductChange(prodNumber, prodCount, prodCountOld) {
      if (prodCount > prodCountOld) {
        message.info("введите количество не более " + prodCountOld)
        return false
      }

      this.correctedProducts[prodNumber] = {
        number: prodNumber,
        count: prodCount,
        countOld: prodCountOld,
      }
    },
    async saveCorrection(orderUuid, items) {
      const data = {
        uuid: orderUuid,
        products: items
          .filter(item => parseInt(item.count) !== parseInt(item.countOld))
          .map(({ number, count }) => ({ number, count })),
      }

      if (data.products.length === 0) {
        message.info("нет товаров для коррекции.")
        return false
      }

      let correctionId = null

      try {
        let response = await this.storeCorrection(data)
        correctionId = response.data.correction_id
      } catch (error) {
        if (error.response) {
          message.error(error.response.data.message)
        }
      }

      this.setCorrectionId(correctionId)
      this.statusToCorrection(correctionId)
    },

    statusToCorrection(correctionId) {
      let vm = this
      let formData = new FormData()
      formData.append("orderUuid", this.order.uuid)
      formData.append("comment", this.comment)
      formData.append("correction_id", correctionId)

      this.setStatusCorrection(formData)
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          vm.$emit("send", {})
        })
    },
  },
}
</script>

<style lang="scss"></style>

<template>
  <div class="order content-container" v-show="!isPageLoading">
    <div class="order__action" style="gap: 8px">
      <div class="" style="margin: 30px 0px">
        <template v-if="order !== null">
          <a-button-group>
            <template v-for="orderStatus in showStatusButtons">
              <a-button
                :key="orderStatus.id"
                @click="setStatus(orderStatus.id)"
              >
                {{ orderStatus.action_name }}
              </a-button>
            </template>
            <template>
              <a-button @click="exportExcel">
                Выгрузить в Excel
              </a-button>
            </template>
          </a-button-group>
        </template>
      </div>
    </div>

    <div v-if="showStatusButtons.length" class="order__action">
      <div style="margin: 15px; width: 40%">
        <a-textarea
          :auto-size="{ minRows: 2, maxRows: 6 }"
          placeholder="комментарий..."
          v-model="comment"
        />
        <div v-if="isSupplier" style="text-align: right;margin-top: 5px;">
          <a-button @click="saveComment" :disabled="commentEmpty">
            Сохранить
          </a-button>
        </div>
      </div>
    </div>

    <div class="order__content" v-if="order">
      <h1 class="order__title">Заказ {{ order.name }}</h1>
      <div class="order__item">
        <h2>Данные заказа</h2>
        <ul class="order__list">
          <li class="order__list-item">
            <span>Уникальный ID</span>
            <span>{{ order.uuid }}</span>
          </li>
          <li class="order__list-item">
            <span>Дата</span>
            <span>{{ order.created_at | date }}</span>
          </li>
          <li class="order__list-item" v-if="order.dealer">
            <span>Бренд</span>
            <span>{{ order.dealer.name }}</span>
          </li>
        </ul>
      </div>
      <div class="order__item">
        <h2>Клиент</h2>
        <ul class="order__list">
          <li class="order__list-item">
            <span>Наименование дилера</span>
            <span>{{ order.basket.client.name }}</span>
          </li>
          <li class="order__list-item">
            <span>Код дилера</span>
            <span>{{ order.basket.client.code }}</span>
          </li>
          <li class="order__list-item">
            <span>E-mail</span>
            <span>{{ order.recipient.email }}</span>
          </li>
          <li class="order__list-item">
            <span>Телефон</span>
            <span>{{ order.recipient.phone }}</span>
          </li>
          <li class="order__list-item">
            <span>Получатель</span>
            <span>
              {{ `${order.recipient.last_name}` }}
            </span>
          </li>
          <li class="order__list-item">
            <span>Адрес доставки</span>
            <span>
              {{ `${order.delivery.address}` }}
            </span>
          </li>
          <li class="order__list-item">
            <span>
              Ограничения по массогабаритным характеристикам транспорта
            </span>
            <span>
              {{ order.basket.client.mass_restrict }}
            </span>
          </li>
        </ul>
      </div>
      <div class="order__item">
        <h2>Товары</h2>
        <a-list
          :data-source="orderBasket.products"
          :rowKey="e => e.id"
          item-layout="horizontal"
        >
          <a-list-item
            slot="renderItem"
            slot-scope="item"
            v-bind:class="[item.isCorrected ? 'corrected' : '']"
          >
            <div class="ant-list-item-meta">
              <div class="ant-list-item-meta-content">
                <h4 class="ant-list-item-meta-title">
                  <span>{{ item.catalog_product.name }}</span>
                </h4>
                <div class="ant-list-item-meta-description">
                  <a-row>
                    <a-col span="10">Количество после коррекции:</a-col>
                    <a-col span="4">
                      <template v-if="modeProductions === 'read'">
                        {{ item.count }}
                      </template>
                      <template v-if="modeProductions === 'edit'">
                        <a-input
                          type="number"
                          :value="item.count"
                          @change="handleProductCountChange($event, item.id)"
                        ></a-input>
                      </template>
                    </a-col>
                    <a-col span="10" style="text-align: end">
                      <template v-if="item.isCorrected">
                        <span style="display: flex; flex-direction: column">
                          <strike>
                            {{
                              (item.calculate_price * item.countOld)
                                | toCurrency
                            }}
                          </strike>
                          <strong>
                            {{
                              (item.calculate_price * item.countNew)
                                | toCurrency
                            }}
                          </strong>
                        </span>
                      </template>
                      <template v-else>
                        <span>
                          {{
                            (item.calculate_price *
                              item.count *
                              item.catalog_product.count)
                              | toCurrency
                          }}
                        </span>
                      </template>

                      <template
                        v-if="
                          isSupplier &&
                            (currentStatusNumber === 16 ||
                              currentStatusNumber === 17)
                        "
                      >
                        <a-select
                          style="width:150px;"
                          v-model="selectedProductStatuses[item.id]"
                          placeholder="Выберите статус"
                          @change="productStatusChanged"
                        >
                          <a-select-option
                            :basket_product_id="item.id"
                            value="5"
                          >
                            Частично отгружен
                          </a-select-option>
                          <a-select-option
                            :basket_product_id="item.id"
                            value="6"
                          >
                            В резерве
                          </a-select-option>
                          <a-select-option
                            :basket_product_id="item.id"
                            value="7"
                          >
                            Отправлен
                          </a-select-option>
                          <a-select-option
                            :basket_product_id="item.id"
                            value="8"
                          >
                            В транзите
                          </a-select-option>
                          <a-select-option
                            :basket_product_id="item.id"
                            value="10"
                          >
                            Отказ
                          </a-select-option>
                        </a-select>
                      </template>
                      <template v-else>
                        <span style="margin-left: 10px;">
                          {{ selectedProductStatuses[item.id] }}
                        </span>
                      </template>
                    </a-col>
                  </a-row>
                  Код: {{ item.catalog_product.code }}
                </div>
              </div>
            </div>
          </a-list-item>
        </a-list>
        <a-space
          v-if="
            modeProductions === 'read' &&
              showStatusButtons.length > 0 &&
              order.permissions.can_edit_count
          "
        >
          <a-button type="primary" @click="productionsEdit()">
            Редактировать
          </a-button>
        </a-space>
        <a-space v-if="modeProductions === 'edit'">
          <a-button type="primary" @click="productionsEditSave()">
            Сохранить
          </a-button>
          <a-button type="primary" @click="productionsEditCancel()">
            Отменить
          </a-button>
        </a-space>
      </div>
      <div class="order__item">
        <h2>Платежные данные</h2>
        <ul class="order__list">
          <li class="order__list-item">
            <span>Сумма</span>
            <span>
              {{ order.price | toCurrency }}
            </span>
          </li>
        </ul>
      </div>
      <div class="order__item">
        <h2>Статус заказа</h2>
        <ul class="order__list">
          <li class="order__list-item">
            <span>Статус</span>
            <span>
              {{ order.current_status.status_name }}
            </span>
          </li>
          <li class="order__list-item">
            <span>Комментарий</span>
            <span>{{ order.current_status.comment }}</span>
          </li>
        </ul>
      </div>

      <div class="order__footer" style="gap: 8px">
        <h3>История</h3>
        <a-collapse class="order-details-collapse" v-model="activeCollapseKey">
          <a-collapse-panel header="Движение заказа" key="1">
            <OrderStatusHistory />
          </a-collapse-panel>
        </a-collapse>
        <a-collapse class="order-details-collapse" v-model="activeCollapseKey">
          <a-collapse-panel header="Комментарии" key="1">
            <OrderComments />
          </a-collapse-panel>
        </a-collapse>

        <a-form-item>
          <a @click="$router.push('/orders')">
            К списку заказов
          </a>
        </a-form-item>
      </div>
    </div>
    <!-- /order__content -->
    <a-modal
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="sendDecline"
      cancel-text="Отмена"
      ok-text="Отправить"
      title="Отмена заказа"
      v-model="showDeclineModal"
    >
      <div>
        <a-textarea
          placeholder="Введите причину отмены"
          required
          v-model="declineComment"
        ></a-textarea>
      </div>
    </a-modal>
    <OrderDetailsCorrection
      :order="order"
      @send="sendCorrection"
    ></OrderDetailsCorrection>
  </div>
</template>
<script>
import OrderService from "@/services/api/v1/OrderService"
import { mapActions, mapGetters, mapMutations } from "vuex"
import moment from "moment"
import OrderDetailsCorrection from "./OrderDetailsCorrection"
import OrderStatusHistory from "./OrderStatusHistory"
import { message } from "ant-design-vue"
import { API_PREFIX } from "@/services/api/v1/const"
import axios from "axios"
import { notification } from "ant-design-vue"
import api from "@/utils/api"
import OrderComments from "@/components/orders/OrderComments"

export default {
  name: "OrderDetails",
  components: {
    OrderComments,
    OrderDetailsCorrection,
    OrderStatusHistory,
  },
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY HH:mm")
    },
  },
  data: () => ({
    order: null,
    activeCollapseKey: ["1"],
    showDeclineModal: false,
    declineComment: "",
    confirmLoading: false,
    refundVisible: false,
    refundCount: 0,
    refundProductNumber: 0,
    refundOrderUuid: 0,
    refundComment: "",
    refundedProducts: [],
    previewVisible: false,
    previewImage: "",
    comment: "",
    showStatusButtons: [],
    correctedProducts: [],
    modeProductions: "read",
    selectedProductStatuses: {},
  }),
  computed: {
    ...mapGetters({
      isPageLoading: "auth/isPageLoading",
      orders: "orders/orders",
      isClient: "auth/isDealer",
      isManufacturer: "auth/isManufacturer",
      isManufacturerAdmin: "auth/isManufacturerAdmin",
      isSupplier: "auth/isSupplier",
      isAdmin: "auth/isAdmin",
      orderStatuses: "orders/orderStatuses",
      statusButtonsMatrix: "orders/statusButtonsMatrix",
      orderCorrections: "orders/orderCorrections",
      refundItems: "orders/refundItems",
      statusMethods: "orders/statusMethods",
    }),
    commentEmpty() {
      return this.comment === ""
    },
    currentStatusNumber() {
      return this.order === null ? null : this.order.current_status.status_id
    },
    orderCorrectionId() {
      return this.order?.current_status.info.correction_id
        ? this.order.current_status.info.correction_id
        : null
    },
    orderCorrectionsCurrent() {
      return this.orderCorrections.filter(
        item =>
          parseInt(item.correction_id) === parseInt(this.orderCorrectionId)
      )
    },
    // Подсветка скорректированных и возвращенных товаров
    orderBasket: {
      get() {
        return this.order.basket
      },
      set(basket) {
        this.setOrderBasket(basket)
      },
    },
    orderPrice() {
      let totalPrice = 0
      this.order.basket.products.map(item => {
        totalPrice += item.count * item.calculate_price
      })
      return totalPrice
    },
  },
  watch: {
    activeCollapseKey(key) {
      console.log(key)
    },
  },
  mounted() {
    this.getOrderData()
  },
  methods: {
    productionsEdit() {
      this.modeProductions = "edit"
    },
    productionsEditCancel() {
      this.modeProductions = "read"
      this.correctedProducts = []
      this.getOrderData()
    },
    productionsEditSave() {
      this.saveProductsChanged()
    },
    handleProductCountChange(event, index) {
      let correctedProduct,
        found = false
      for (const key in this.correctedProducts) {
        correctedProduct = this.correctedProducts[key]
        if (correctedProduct.basket_product_id === index) {
          correctedProduct.count = event.target.value
          found = true
          break
        }
      }
      if (!found) {
        this.correctedProducts.push({
          basket_product_id: index,
          count: event.target.value,
        })
      }
    },
    async saveProductsChanged() {
      if (this.correctedProducts.length === 0) {
        this.productionsEditCancel()
      }
      const response = await this.verifyBasket(this.correctedProducts)
      if (response.data.data.length > 0) {
        let descr = response.data.data.map(item => {
          return item
        })
        notification.open({
          message: response.data.message,
          description: descr.join(" "),
          duration: 0,
          style: {
            width: "600px",
            marginLeft: `${100 - 600}px`,
          },
        })
        return false
      }
      this.productionsEditCancel()
    },
    async saveComment() {
      let formData = new FormData()
      formData.append("comment", this.comment)
      try {
        // eslint-disable-next-line no-unused-vars
        console.log("uuid", this.order.uuid)
        console.log("comment", this.comment)
        await this.saveOrderComment({
          orderUuid: this.order.uuid,
          formData: formData,
        })

        this.$message.success("Комментарий добавлен")
        await this.getOrderData()
      } catch (error) {
        if (error.response) {
          message.error(error.response.data.message)
        }
      }

      this.comment = ""
    },
    resetRefundData() {
      this.confirmLoading = false
      this.refundVisible = false
      this.refundProductNumber = 0
      this.refundCount = 0
      this.refundComment = ""
    },
    async productStatusChanged(value, option) {
      await this.updateProductStatus({
        basket_product_id: option.data.attrs.basket_product_id,
        supplier_status_id: value,
      })
      await this.getOrderData()
    },
    async onRefund() {
      if (!this.refundedProducts.length) {
        this.$confirm({
          title: "Вы действительно хотите сделать полный возврат?",
          content: "",
          okText: "Да",
          okType: "danger",
          cancelText: "Нет",
          onOk() {
            this.setStatus(5)
          },
          onCancel() {},
        })
      }

      if (this.refundedProducts.length) this.setStatus(5)
    },
    async sendDecline() {
      this.confirmLoading = true
      const srv = new OrderService()
      const response = await srv.declineOrder(this.order, this.declineComment)
      this.order.status = response.data.status
      this.order.status_comment = response.data.status_comment
      this.confirmLoading = false
      this.showDeclineModal = false
    },
    async getOrderData() {
      this.setPageLoading(true)
      const { id = null } = this.$route.params
      if (id) {
        const order = this.orders.find(item => item.uuid === id)
        if (order) {
          this.order = order
        } else {
          this.order = await this.getOrder(id)
          await this.fetchOrderStatuses()
          await this.fetchOrderCorrections(id)
          await this.fetchOrderComments(id)
          for (const i in this.order.basket.products) {
            const product = this.order.basket.products[i]
            this.selectedProductStatuses[product.id] =
              product.supplier_status_name
          }
        }
        await this.getActions(this.order.uuid).data
      }

      this.setPageLoading(false)
    },

    async getActions(uuid) {
      const result = await axios.get(`${API_PREFIX}/order/${uuid}/actions`)
      this.showStatusButtons = result.data.data
    },

    prepareFormData() {
      let formData = new FormData()

      formData.append("orderUuid", this.order.uuid)
      formData.append("comment", this.comment)

      return formData
    },
    async setStatus(orderStatusId) {
      if (this.order == null) return false

      const formData = this.prepareFormData()

      // Validations
      if (
        this.statusMethods[orderStatusId]?.needComment &&
        formData.get("comment") === ""
      ) {
        this.$message.error("Введите комментарий")
        return false
      }
      // @Validations

      const method = this.statusMethods[orderStatusId]["m"]

      try {
        await this[method](formData)
      } catch (error) {
        if (error.response) {
          message.error(error.response.data.message)
        }
      }

      this.getOrderData()
    },
    async setStatusPaid(data) {
      await this.setOrderPaid(data)
      this.getOrderData()
    },
    showCorrection() {
      this.setCorrectionModalVisible(true)
    },
    hideCorrection() {
      this.setCorrectionModalVisible(false)
    },
    sendCorrection() {
      this.hideCorrection()
      this.getOrderData()
    },
    exportExcel() {
      const params = {
        uuid: this.order.uuid,
      }
      api.downloadFileFromPost(`/order/print`, params)
    },
    ...mapMutations({
      setOrderBasket: "orders/SET_ORDER_BASKET",
      setCorrectionModalVisible: "orders/SET_CORRECTION_MODAL_VISIBLE",
      setPageLoading: "auth/SET_PAGE_LOADING",
    }),
    ...mapActions({
      getOrder: "orders/getOrder",
      updateProductStatus: "basket/updateProductStatus",
      fetchOrderStatuses: "orders/getOrderStatuses",
      fetchCurrentOrderStatuses: "orders/getCurrentOrderStatuses",
      fetchOrderCorrections: "orders/getOrderCorrections",
      fetchOrderComments: "orders/getOrderComments",
      saveOrderComment: "orders/saveOrderComment",

      setStatusInWork: "orders/setStatusInWork",
      setStatusConfirmed: "orders/setStatusConfirmed",
      setStatusCompleted: "orders/setStatusCompleted",
      setStatusRefund: "orders/setStatusRefund",
      setStatusConsideration: "orders/setStatusConsideration",
      setStatusRefundConfirmed: "orders/setStatusRefundConfirmed",
      setStatusCorrection: "orders/setStatusCorrection",
      setStatusCanceledSupplier: "orders/setStatusCanceledSupplier",
      setStatusCorrectionCompleted: "orders/setStatusCorrectionCompleted",
      setStatusCanceledDealer: "orders/setStatusCanceledDealer",
      setStatusRefundCanceled: "orders/setStatusRefundCanceled",
      setStatusEdited: "orders/setStatusEdited",
      setStatusCanceledManufacturer: "orders/setStatusCanceledManufacturer",
      setStatusUnderConsideration: "orders/setStatusUnderConsideration",
      setStatusInStock: "orders/setStatusInStock",
      setStatusInTransit: "orders/setStatusInTransit",
      setStatusShippedSupplier: "orders/setStatusShippedSupplier",
      setStatusRefundCompleted: "orders/setStatusRefundCompleted",
      setOrderPaid: "orders/setOrderPaid",
      verifyBasket: "orders/verifyBasket",
    }),
  },
}
</script>
<style lang="scss">
$wrapper-width: 600px;

.order {
  background-color: white;
  &__title {
    font-size: 32px;
    text-align: center;
    line-height: 1.3;
  }

  h2 {
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 2rem;
    font-weight: 900;
    font-size: 18px;
  }

  &__item {
    margin: 0 10px;
    @media (min-width: 1400px) {
      max-width: 600px;
      margin: 0 auto;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__list-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
    border-bottom: 1px solid #ececec;
  }

  &__action {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    width: 100%;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .order-upload {
    width: 100%;
    max-width: $wrapper-width;
  }

  /* you can make up upload button and sample style by using stylesheets */
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .order-details-refund {
    width: 100%;
    max-width: $wrapper-width;
  }

  .order-details-collapse {
    width: 100%;
    max-width: $wrapper-width;
  }

  .correction-details {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .corrected {
    background: #f3ff4d36;
    padding: 10px;
  }
}
</style>

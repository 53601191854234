<template>
  <div>
    <OrderDetails />
  </div>
</template>

<script>
import OrderDetails from "@/components/orders/OrderDetails"

export default {
  name: "Order",
  components: {
    OrderDetails,
  },
}
</script>
